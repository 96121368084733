<template>
    <div class="tgju-widgets-row dashboard-widgets-technical-list dash-full-height tgcss-relative font-IRANSans">
        <div class="tgcss-fixed tgcss-top-[60px] tgcss-z-30 tgcss-bg-white tgcss-w-full tgcss-px-2 tgcss-py-3">
            <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-px-1">
                <div class="tgcss-cursor-pointer" @click="back()">
                    <svg class="tgcss-w-8 tgcss-border tgcss-rounded-md tgcss-ml-2 !tgcss-text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
                </div>
                <span class="tgcss-text-base tgcss-font-bold grow">{{ $route.query.subject }}</span> 
            </div>
        </div>

        <slot v-if="list[$route.params.category_id]">
            <div class="tgcss-py-4 tgcss-pt-[60px] tgcss-px-3">
                <newsRowSlide v-for="(item, index) in list[$route.params.category_id].list" :key="index" :id="item.id" :cat="item.id" :count="10" :title="item.title" :type="item.type" />
            </div>
            
        </slot>
        <slot v-else>

            <div class="market-news-list tgcss-py-4 tgcss-pt-[60px]">

                <div v-if="categoryId" class="tgcss-w-full">
                    <router-link  v-for="(item, index) in categoryId.data" :key="index" :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }}" class="!tgcss-no-underline !tgcss-text-gray-800">
                        <div class="tgcss-flex tgcss-items-start tgcss-px-3 tgcss-border-b tgcss-border-gray-300 tgcss-border-dashed tgcss-w-full tgcss-py-3 tgcss-overflow-hidden"
                        :class="{ ' !tgcss-pt-0 ': index == 0,' !tgcss-border-0 ': index == 4}"
                        >
                            <div class="tgcss-border tgcss-flex tgcss-items-center tgcss-justify-center tgcss-rounded-lg tgcss-overflow-hidden tgcss-h-20 tgcss-min-h-20 tgcss-w-20 tgcss-min-w-20 tgcss-mt-0.5 tgcss-ml-3">
                                <img v-if="item.image" class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.image" :alt="item.title">
                                <img v-if="!item.image" class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" src="https://static.tgju.org/views/default/images/news/no-img.png" :alt="item.title">

                            </div>
                            <div class="tgcss-grow">
                                <div class="tgcss-w-full tgcss-h-[62px]">
                                    <div class="tgcss-font-semibold tgcss-text-gray-400 tgcss-flex tgcss-items-center tgcss-text-xs2 tgcss-line-clamp-1 tgcss-text-ellipsis tgcss-overflow-hidden">
                                        {{ item.title_second }}
                                    </div>
                                    <div class="tgcss-font-semibold tgcss-h-12 tgcss-flex tgcss-items-start tgcss-text-sm tgcss-leading-6 tgcss-mb-0.5 tgcss-line-clamp-2 tgcss-text-ellipsis tgcss-overflow-hidden">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <div class="tgcss-text-xs2 tgcss-pr-1 tgcss-font-medium tgcss-text-gray-400 tgcss-flex tgcss-items-center">
                                    <router-link :to="{ name: 'ApplicationNews' , params: { category_id: item.category_id, }}"  class="tgcss-py-0.5 tgcss-ml-2 !tgcss-no-underline  -tgcss-mr-1 tgcss-font-semibold tgcss-text-xs2 tgcss-px-2 !tgcss-bg-[#ffefd2] !tgcss-text-[#a68953] tgcss-rounded-full">{{ item.category_title }}</router-link>
                                    {{ parseDate(item.jalali_created_at).dayOfWeek }} {{ parseDate(item.jalali_created_at).day }} {{ parseDate(item.jalali_created_at).monthName }} {{ parseDate(item.jalali_created_at).year }}
                                    
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
            <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner tgcss-mb-6">
                    <div class="widget-loading tgcss-mb-4"></div>
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </slot>

    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import newsRowSlide from '@/components/Inc/newsRowSlide.vue'

export default {
    name: 'ApplicationNews',
    props: ['subject'],
    components: {
        InfiniteLoading,
        newsRowSlide
    },
    data() {
        return {
            categories: null,
            categoryId: null,
            loading: true,
            page: 2,
            
            list: {
                "97042": {
                    id:97042,
                    name:'بازارهای معاملاتی',
                    list:[
                        {id:'93964',type:'category-news',title:'آموزش های پایه'},
                        {id:'93980',type:'category-news',title:'آموزش معاملات'},
                        {id:'93983',type:'category-news',title:'قوانین بازار رمزارز'},
                        {id:'93981',type:'category-news',title:'آموزش‌های توسعه‌دهندگان'},
                        {id:'94031',type:'category-news',title:'راهنمای جامع بازارهای آتی'},
                    ]
                },
                "97041": {
                    id:97041,
                    name:'آموزش تحلیل بازارها',
                    list:[
                        {id:'97051',type:'category-news',title:'راهنمای جامع تحلیل بازارها'},
                        {id:'93978',type:'category-news',title:'راهنمای جامع اندیکاتورها'},
                    ]
                },
                "97048": {
                    id:97048,
                    name:'آموزش‌های معامله گری',
                    list:[
                        {id:'97052',type:'category-news',title:'مبانی معاملاتی'},
                        {id:'97809',type:'category-news',title:'مدیریت ریسک در معاملات'},
                        {id:'94030',type:'category-news',title:'آموزش پایه ترید و معاملات'},
                    ]
                },
            },
        }
    },
    mounted() {
        // this.getCategories();
        this.getCategoryId();
        this.tab = this.$store.state.currentTabMarket;
        this.tab = this.list.findIndex(p => p.id == this.$route.params.category_id);
        Vue.use(InfiniteLoading, {
            slots: {
                noMore: "محتوا کاملاً بارگیری شدند", // you can pass a string value
            },
        });
    },
    methods: {
        hasNumber(string) {
            return /\d/.test(string);
        },
        getCurrentMarketTab(id) {
            this.$store.commit('setCurrentTabMarket', id);
            if(this.$route.params.category_id != id){
                this.$router.push({ name: "ApplicationNews", params: { category_id: id, },} );
            }
        },
                    
        getCategoryId($page) {
            this.loading = true;
            if(!this.hasNumber(this.$route.params.category_id)){
                this.$helpers
                .makeRequest("GET", `/news/category-tag`, { category_id: this.$route.params.category_id })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.categoryId = api_response.data.response.detail;
                        this.loading = false;
                    }
                });
            }else{
                this.$helpers
                .makeRequest("GET", `/news/category-id`, { category_id: this.$route.params.category_id })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.categoryId = api_response.data.response.detail;
                        this.loading = false;
                    }
                });
            }
        }, 
        infiniteHandler($state) {
            if(!this.hasNumber(this.$route.params.category_id)){
                this.$helpers
                .makeRequest("GET", `/news/category-tag`, { category_id: this.$route.params.category_id , page: this.page})
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.data)) {
                                this.categoryId.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                }); 
            }else{
                this.$helpers
                .makeRequest("GET", `/news/category-id`, { category_id: this.$route.params.category_id , page: this.page})
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.data)) {
                                this.categoryId.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                }); 
            }
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
        
        parseDate(dateString) {
                // تاریخ ورودی: یکشنبه 5 اسفند 1403 ساعت 11:31
                const months = [
                    'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
                    'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
                ];
                
                // روزهای هفته
                const daysOfWeek = [
                    'شنبه', 'یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه'
                ];
                
                // جدا کردن روز، ماه، سال
                const dateParts = dateString.match(/(\D+)\s(\d+)\s(\D+)\s(\d+)/);

                const dayOfWeek = dateParts[1];  // روز هفته (فارسی)
                const day = dateParts[2];        // روز
                const month = months.indexOf(dateParts[3]) + 1;  // ماه به شماره
                const monthName = dateParts[3];  // ماه به اسم فارسی
                const year = dateParts[4];       // سال
                
                return { dayOfWeek, day, month, monthName, year };
            },
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
    },
}
</script>
